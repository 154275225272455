/* Header.css */

.header {
  background-color: white;
  color: #282c34;
  padding: 20px;
  width: 100%;
  display: flex;
  justify-content: center; /* Center items horizontally */
  align-items: center;
  box-sizing: border-box;
  position: relative;
}

.header .title {
  margin: 0;
  font-size: 24px;
  font-weight: bold;
  z-index: 0; /* Ensure the title stays below the logo and navigation */
}

.header img.logo {
  width: 50px;
  height: auto;
  position: absolute;
  left: 20px; /* 20px buffer from the left edge of the header */
  top: 50%; /* Center vertically */
  transform: translateY(-50%);
  z-index: 1; /* Ensure the logo is above other elements */
}

.header-navigation {
  position: absolute;
  right: 20px; /* Position the navigation component on the right */
  top: 50%;
  transform: translateY(-50%);
  z-index: 1; /* Ensure the navigation is above other elements */
}

.header::before {
  content: '';
  position: absolute;
  bottom: -3px; /* Adjust position if needed */
  left: 20px;
  right: 20px;
  height: 3px;
  background-color: black;
}

/* Hide title on small screens */
@media screen and (max-width: 750px) {
  .header .title {
    color: white;
  }
}