.alpr-description {
    background-color: #f9f9f9;
    padding: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    margin: 0 auto;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 800px;
    text-align: left;
    align-items: center;
  }
  
  .alpr-description h2 {
    color: #282c34;
    margin-bottom: 10px;
  }
  
  .alpr-description h3 {
    color: #1248f8;
    margin-top: 20px;
  }
  
  .alpr-description p {
    color: #333;
    line-height: 1.6;
  }
  
  .alpr-description ul {
    list-style-type: disc;
    margin: 10px 0;
    padding-left: 20px;
  }
  
  .alpr-description li {
    margin: 5px 0;
    color: #333;
  }
  