.product-component {
    display: flex;
    align-items: center;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin: 10px auto;
    background-color: #f9f9f9;
    max-width: 800px;
  }
  
  .product-logo {
    width: 100px;
    height: auto;
    margin-right: 20px;
  }
  
  .product-text {
    flex-grow: 1;
    text-align: left;
  }
  
  .product-text h2 {
    margin: 0 0 10px;
    font-size: 24px;
  }
  
  .product-text p {
    margin: 0;
    font-size: 16px;
  }
  