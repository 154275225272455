/* Global.css */

@font-face {
  font-family: 'Antium';
  src: url('./fonts/Antium.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

body {
  font-family: 'Antium', Arial, sans-serif;
}

/* Global CSS */

html, body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow-x: hidden; /* Prevent horizontal scroll */
}

* {
  box-sizing: border-box;
}

/* Remove any extra borders that might be causing issues */
.header::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 20px;
  right: 20px;
  height: 0; /* Remove the height to eliminate any extra line */
  background-color: transparent; /* Make it transparent */
}
