.main-content {
    margin: 0 auto; /* Center the content horizontally */
    max-width: 1200px; /* Optional: set a max width to keep the content from getting too wide */
    box-sizing: border-box;
  }
  
  @media (max-width: 800px) {
    .main-content {
      margin-left: 10px; /* Add margin on all sides */
      margin-right: 10px;
    }
  }