/* ContactContent.css */

.contact-content {
    padding: 20px;
    text-align: center;
  }
  
  .contact-content h2 {
    color: #1248f8;
  }
  
  .contact-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px; /* Space between text and button */
    padding: 20px;
  }
  
  .contact-text {
    margin: 0;
  }
  
  .contact-button {
    background-color: #1248f8;
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    font-weight: bold;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .contact-button:hover {
    background-color: #21a1f1;
  }
  
  .email {
    color: #1248f8;
    cursor: pointer;
    text-decoration: underline;
  }
  
  .email:hover {
    color: #002ab6;
  }
  