/* MainContent.css */

.main-content {
    padding: 20px;
    padding-bottom: 0px;
    text-align: center;
  }
  
  .main-content h2 {
    color: #1248f8;
  }
  